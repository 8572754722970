import React from "react";
import Navbar from "./components/Navbar";
import Home from "./sections/Home";
import About from "./sections/About";
import Products from "./sections/Products";
import Contact from "./sections/Contact";

const App = () => {
  return (
    <div className="App">
      <Navbar />
      <Home />
      <About />
      <Products />
      <Contact />
    </div>
  );
};

export default App;
