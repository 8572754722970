export default {
  mainHeader: {
    en: "Contact Us",
    ita: "Contattaci",
    de: "Kontaktiere uns"
  },
  subHeader: {
    en: "Send us a Message",
    ita: "Mandaci un messaggio",
    de: "Schick uns eine Nachricht"
  },
  name: {
    en: "Name",
    ita: "Nome",
    de: "Name"
  },
  message: {
    en: "Message",
    ita: "Messaggio",
    de: "Nachricht"
  },
  nameError: {
    en: "Name cannot be left empty!",
    ita: "Il nome non può essere lasciato vuoto!",
    de: "Der Name darf nicht leer bleiben!"
  },
  emailError: {
    en: "Email cannot be left empty!",
    ita: "L'email non può essere lasciata vuota!",
    de: "Die E-mail darf nicht leer bleiben!"
  },
  messageError: {
    en: "Please do not leave the message empty!",
    ita: "Si prega di non lasciare il messaggio vuoto!",
    de: "Bitte lassen Sie die Nachricht nicht leer!"
  },
  submit: {
    en: "Submit",
    ita: "Invia",
    de: "Einreichen"
  },
  emailSendingError: {
    en: "Failed to send message! Please check your network connection!",
    ita:
      "Impossibile inviare il messaggio! Per favore controlla la tua connessione Internet!",
    de:
      "Nachricht konnte nicht gesendet werden! Bitte überprüfen Sie Ihre Internetverbindung!"
  },
  emailSent: {
    en: "Message sent!",
    ita: "Messaggio inviato!",
    de: "Nachricht gesendet!"
  }
};
