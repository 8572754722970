const nav = {
  about: {
    en: "About us",
    ita: "Riguardo a noi",
    de: "Über uns"
  },
  products: {
    en: "Products",
    ita: "Prodotti",
    de: "Produkte"
  },
  contact: {
    en: "Contact",
    ita: "Contatto",
    de: "Kontakt"
  }
};

export default nav;
