const homeSlider = {
  header: {
    en: "The best agriculture has to offer",
    ita: "La migliore agricoltura ha da offrire",
    de: "Die beste Landwirtschaft hat zu bieten"
  },
  subheader: {
    en:
      "We promote organic and certified albanian products and we guarantee 100% quality for the products that we offer. We may not be the biggest, but we surely are one of the best.",
    ita:
      "Promuoviamo prodotti albanesi biologici e certificati e garantiamo il 100% di qualità per i prodotti che offriamo. Potremmo non essere i più grandi, ma sicuramente siamo i migliori.",
    de:
      "Wir fördern biologische und zertifizierte albanische Produkte und garantieren 100% Qualität für die von uns angebotenen Produkte. Wir sind vielleicht nicht die Größten, aber wir sind sicherlich einer der Besten."
  }
};

export default homeSlider;
