import React from "react";
import { useSelector } from "react-redux";
import homeSlider from "../constants/home";

const HomeSection = () => {
  const lang = useSelector(store => store.language);
  return (
    <div className="home" id="home">
      <div className="home--headerContainer">
        <div className="home--header">
          <h1 className="home--header__text">{homeSlider.header[lang]}</h1>
          <p>{homeSlider.subheader[lang]}</p>
        </div>
      </div>
      <div className="home--slider">
        <div className="home--slider__clip"></div>
      </div>
    </div>
  );
};

export default HomeSection;
