import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setLanguage } from "../store/actions";
import nav from "../constants/nav";

const Navbar = () => {
  const language = useSelector(store => store.language);
  const dispatch = useDispatch();

  const setLang = language => {
    dispatch(setLanguage(language));
  };

  const handleHamburger = () => {
    document.querySelector(".nav-links").classList.toggle("open");
    document.querySelectorAll(".nav-links li").forEach(link => {
      link.classList.toggle("fade");
    });
    document.querySelector(".lines").classList.toggle("open");
  };

  const closeHamburger = () => {
    document.querySelector(".nav-links").classList.remove("open");
    document.querySelectorAll(".nav-links li").forEach(link => {
      link.classList.remove("fade");
    });
    document.querySelector(".lines").classList.remove("open");
  };

  return (
    <nav>
      <div className="nav-content-wrapper">
        <div className="nav-brand">
          <a href="#home">
            <img src={`/assets/logo.png`} alt="" />
          </a>
        </div>
        <div className="hamburger" onClick={handleHamburger}>
          <div className="lines"></div>
        </div>
        <div className="listWrapper">
          <ul className="nav-links">
            {Object.keys(nav).map(section => (
              <li key={section}>
                <a href={`#${section}`} onClick={closeHamburger}>
                  {nav[section][language]}
                </a>
              </li>
            ))}
          </ul>
          <ul className="nav-flags">
            {["ita", "en", "de"].map(lang => (
              <li key={lang}>
                <img
                  onClick={() => setLang(lang)}
                  id={lang}
                  src={`/assets/${lang}.png`}
                  alt=""
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
