import React, { useState } from "react";
import { useSelector } from "react-redux";
import contact from "../constants/contact";
import axios from "axios";
import Footer from "./Footer";

const ContactSection = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const lang = useSelector(store => store.language);

  const handleSave = async e => {
    e.preventDefault();
    setErrorMessage("");
    setIsLoading(true);

    if (name === "") {
      setErrorMessage(contact.nameError[lang]);
      return;
    } else if (email === "") {
      setErrorMessage(contact.emailError[lang]);
      return;
    } else if (message === "") {
      setErrorMessage(contact.messageError[lang]);
      return;
    }

    // Send info to backend
    const response = await axios.post(
      "https://agriballkanika.herokuapp.com/sendMail",
      {
        name,
        email,
        message,
        language: lang
      },
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    );

    if (response.data.msg === "failed") {
      setErrorMessage(contact.emailSendingError[lang]);
    } else {
      alert(contact.emailSent[lang]);
    }
    setIsLoading(false);
  };

  return (
    <div id="contact" className={isLoading ? "cursor-wait" : ""}>
      <div className="contact-content-wrapper">
        <h1>{contact.mainHeader[lang]}</h1>
        <p>{contact.subHeader[lang]}</p>
        <form onSubmit={handleSave}>
          <input
            type="text"
            placeholder={contact.name[lang]}
            value={name}
            onChange={e => setName(e.target.value)}
          />
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <textarea
            cols="30"
            rows="10"
            placeholder={contact.message[lang]}
            value={message}
            onChange={e => setMessage(e.target.value)}
          >
            Message...
          </textarea>

          {errorMessage.length ? (
            <div className="error">{errorMessage}</div>
          ) : null}
          <button type="submit" disabled={isLoading}>
            {contact.submit[lang]}
          </button>
        </form>
      </div>

      <Footer />
    </div>
  );
};

export default ContactSection;
