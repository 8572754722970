import React from "react";
import footer from "../constants/footer";
import { useSelector } from "react-redux";

const Footer = () => {
  const lang = useSelector(store => store.language);

  return (
    <footer>
      <div className="phone-numbers">
        <i className="fas fa-phone"></i>
        <span>+49 173 4059312</span>
        <span className="separator">|</span>
        <span>+355 67 402 2144</span>
      </div>
      <div className="copyright">
        &copy; Copyright 2020 Agribalkanika. {footer.rightsReserved[lang]}
      </div>
    </footer>
  );
};

export default Footer;
