const about = {
  mainHeader: {
    en: "Who are we?",
    ita: "Chi siamo noi?",
    de: "Wer sind wir?"
  },
  paragraphHeader: {
    en: "Albanian leader of agricultural exports",
    ita: "Leader albanese delle esportazioni agricole",
    de: "Albanischer Führer der Agrarexporte"
  },
  firstParagraph: {
    en:
      "The company was born and started its commercial activity in 2017. With a highly experienced staff, the main goal of our company is to provide agricultural products of highest quality in both national and international markets.",
    ita:
      "La società è nata e ha iniziato la sua attività commerciale nel 2017. Con uno staff di grande esperienza, l'obiettivo principale della nostra azienda è fornire prodotti agricoli di altissima qualità sia sui mercati nazionali che internazionali.",
    de:
      "Das Unternehmen wurde 2017 gegründet und hat seine Geschäftstätigkeit aufgenommen. Mit seinen erfahrenen Mitarbeitern ist es das Hauptziel unseres Unternehmens, landwirtschaftliche Produkte von höchster Qualität auf nationalen und internationalen Märkten anzubieten."
  },
  secondParagraph: {
    en:
      "Our crops are mainly represented by fruit and vegetables grown in our greenhouses or in the open field. These include but are not limited to peppers, melons, tomatoes, blackberries, cherries, strawberries, pumpkins, lettuces, apples,  grapes, yellowbeans, peas,  watermelons, tangerines, cucumbers, redberries, eggplants.",
    ita:
      "Le nostre colture sono rappresentate principalmente da frutta e verdura coltivate nelle nostre serre o in pieno campo. Questi includono ma non sono limitati a peperoni, meloni, pomodori, more, ciliegie, fragole, zucche, lattughe, mele, uva, fagioli gialli, piselli, angurie, mandarini, cetrioli, mirtilli, melanzane.",
    de:
      "Unsere Pflanzen werden hauptsächlich durch Obst und Gemüse repräsentiert, das in unseren Gewächshäusern oder auf freiem Feld angebaut wird. Dazu gehören unter anderem Paprika, Melonen, Tomaten, Brombeeren, Kirschen, Erdbeeren, Kürbisse, Salat, Äpfel, Trauben, Gelbbohnen, Erbsen, Wassermelonen, Mandarinen, Gurken, Redberries und Auberginen."
  }
};

export default about;
